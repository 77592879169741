<header>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <div><a href="https://portal.azure.com/#@nityachinmaygmail.onmicrosoft.com/" class="navbar-brand">
                Born2Learn</a></div>

        <ul class="navbar-nav">
            <li><a *ngIf="this.authenticationService.isUserLoggedIn()" routerLink="/welcome/born2learn"
                    class="nav-link">Home</a></li>
            <li><a *ngIf="this.authenticationService.isUserLoggedIn()" routerLink="/todos" class="nav-link">Todos</a>
            </li>
        </ul>

        <ul class="navbar-nav navbar-collapse justify-content-end">
            <li><a *ngIf="!this.authenticationService.isUserLoggedIn()" routerLink="/login" class="nav-link">Login</a>
            </li>
            <li><a *ngIf="this.authenticationService.isUserLoggedIn()" routerLink="/logout" class="nav-link">Sign
                    me
                    Out</a>
            </li>
        </ul>
    </nav>
</header>