<H1>Login!</H1>

<div class="container">
  <div class="alert alert-warning" *ngIf='invalidLogin'>{{errorMessage}}</div>

  <form>
    <fieldset class="form-group">
      <label>User Name </label>
      <input type="text" name="username" class="form-control" [(ngModel)]="username">
    </fieldset>
    <fieldset class="form-group">
      <label>Password </label>
      <input type="password" name="password" class="form-control" [(ngModel)]="password">
    </fieldset>

    <button (click)=handleLogin() class="btn btn-primary mr-1">Hardcoded Login</button>
    <button (click)=handleAWSCognitoLogin() class="btn btn-success mr-1">Login with Cognito</button>
    <a href="https://login.microsoftonline.com/cbae7e7a-3c8e-43bc-83a7-733a9aaab261/oauth2/v2.0/authorize?client_id=0b2b3418-3b9f-4919-a1b9-4bfda06ce5bb&nonce=defaultNonce&redirect_uri=https%3A%2F%2Ftodos%2Eborn2learn%2Exyz%2F&scope=openid&response_type=id_token&prompt=login"
      class="btn btn-success mr-1">Azure Login</a>
    <!-- https://login.microsoftonline.com/cbae7e7a-3c8e-43bc-83a7-733a9aaab261/oauth2/v2.0/authorize?client_id=0b2b3418-3b9f-4919-a1b9-4bfda06ce5bb&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3100%2F&scope=openid&response_type=id_token&prompt=login -->
    <!-- https://login.microsoftonline.com/cbae7e7a-3c8e-43bc-83a7-733a9aaab261/oauth2/v2.0/authorize?client_id=0b2b3418-3b9f-4919-a1b9-4bfda06ce5bb&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fchinmaystorage%2Ez13%2Eweb%2Ecore%2Ewindows%2Enet%2F&scope=openid&response_type=id_token&prompt=login -->
  </form>
</div>